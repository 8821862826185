<template>
  <div>
    <b-card no-body class="card-custom" header-tag="header" footer-tag="footer">
      <template v-slot:header>
        <div class="card-title">
          <h3 class="card-label">
            {{ $t("MENU.ITEM.ACTIVITIES.BILL_OF_LADING") }}
            <i class="mr-2"></i>
            <small class="">{{ $t("LABELS.LIST") }}</small>
          </h3>
        </div>
        <div class="card-toolbar">
          <router-link
            v-permission="['bill-of-lading-create']"
            to="add"
            class="btn btn-light-success font-weight-bolder"
            ><i class="flaticon2-plus"></i>
            {{ $t("LABELS.ADD_NEW") }}</router-link
          >
        </div>
      </template>
      <v-card v-show="!isPageLoading">
        <SearchTools
          :searchQuery.sync="searchQuery"
          @do-search="doSearch"
          @do-clear="doClear"
          :placeholder="$t('LABELS.SEARCH_BOL')"
        >
          <template v-slot:prepend>
            <b-col class="pb-0" :md="6">
              <b-form-group id="date-from-group" label-for="date-from-input">
                <FormDatepicker
                  id="date-to-input"
                  type="text"
                  name="date_from"
                  :i18n="{ placeholder: 'FORM_LABELS.DATE_FROM' }"
                  :validateState="{}"
                  v-model="filters.dateFrom"
                ></FormDatepicker>
              </b-form-group>
            </b-col>
            <b-col class="pb-0" :md="6">
              <b-form-group id="date-from-group" label-for="date-from-input">
                <FormDatepicker
                  id="date-from-input"
                  type="text"
                  name="date_to"
                  :i18n="{ placeholder: 'FORM_LABELS.DATE_TO' }"
                  :validateState="{}"
                  v-model="filters.dateTo"
                ></FormDatepicker>
              </b-form-group>
            </b-col>
            <b-col class="pb-0" :md="4">
              <b-form-group id="assignors-group" label-for="assignors-input">
                <FormSelect
                  id="assignors-input"
                  :placeholder="$t('FORM_LABELS.ASSIGNOR')"
                  type="select"
                  name="assignors"
                  :options="assignors"
                  clearable="true"
                  v-model="filters.assignorId"
                  @input="fetchAssignorLocations"
                ></FormSelect>
              </b-form-group>
            </b-col>
            <b-col class="pb-0" :md="4">
              <b-form-group id="locations-group" label-for="locations-input">
                <FormSelect
                  id="locations-input"
                  type="select"
                  :placeholder="$t('FORM_LABELS.LOCATION')"
                  name="locations"
                  :options="locations"
                  clearable="true"
                  v-model="filters.locationId"
                  @input="fetchProtocols"
                ></FormSelect>
              </b-form-group>
            </b-col>
            <b-col class="pb-0" :md="4">
              <b-form-group id="protocols-group" label-for="protocols-input">
                <FormSelect
                  id="protocols-input"
                  type="select"
                  :placeholder="$t('FORM_LABELS.PROTOCOL')"
                  name="protocols"
                  :options="protocols"
                  clearable="true"
                  v-model="filters.protocolId"
                ></FormSelect>
              </b-form-group>
            </b-col>
            <b-col :md="4">
              <b-form-group id="protocols-group" label-for="protocols-input">
                <FormInput
                  type="number"
                  :i18n="{
                    label: 'FORM_LABELS.SEARCH_BY_QTY',
                    placeholder: $t('FORM_LABELS.SEARCH_BY_QTY'),
                  }"
                  :validateState="{}"
                  v-model="filters.serviceQty"
                  @keyup.enter="doSearch"
                ></FormInput>
              </b-form-group>
            </b-col>
          </template>
        </SearchTools>
        <v-data-table
          :headers="tableHeaders"
          :items="items"
          :options.sync="options"
          :items-per-page="totalItems"
          :server-items-length="totalItems"
          :loading="isLoadingBillOfLading"
          :expanded.sync="expanded"
          show-expand
          loading-text="Loading... Please wait"
          :footer-props="{
            showFirstLastPage: true,
            itemsPerPageOptions: rowsPerPageItems,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
          }"
          @update:options="getDataFromApi()"
          class="px-6 elevation-1"
        >
          <template v-slot:[`item.assignorName`]="{ item }">
            {{
              item.assignorId
                ? item.assignorName
                : $t("FORM_LABELS.NO_ASSIGNOR")
            }}
          </template>

          <template v-slot:[`item.protocolName`]="{ item }">
            <span v-if="item.protocolId"
              >{{ item.protocolName }}/{{
                item.protocolDate | moment("DD.MM.YYYY")
              }}</span
            >
            <span v-else-if="item.assignorId && item.locationId">
              {{ $t("FORM_LABELS.NO_PROTOCOL") }}
              <router-link
                v-if="checkIfUserHasPermission(['protocols-create'])"
                :to="{
                  name: 'add-protocols',
                  params: {
                    bol: item,
                  },
                }"
              >
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      width="20"
                      height="20"
                      fab
                      color="success"
                      x-small
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("LABELS.ADD_NEW") }}</span>
                </v-tooltip>
              </router-link>
            </span>
            <span v-else> {{ $t("FORM_LABELS.NO_PROTOCOL") }} </span>
          </template>

          <template v-slot:[`item.locationName`]="{ item }">
            {{
              item.locationId
                ? item.locationName
                : $t("FORM_LABELS.NO_LOCATION")
            }}
          </template>

          <template v-slot:[`item.bolDate`]="{ item }">
            {{ item.bolDate | moment("DD.MM.YYYY") }}
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <router-link
              v-if="checkIfUserHasPermission(['bill-of-lading-update'])"
              :to="{ name: 'edit-bill-of-lading', params: { id: item.id } }"
            >
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="mr-2" fab dark x-small v-bind="attrs" v-on="on">
                    <v-icon dark>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("LABELS.EDIT") }}</span>
              </v-tooltip>
            </router-link>
            <template v-else>Няма права</template>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" color="error" icon="warning">
              Your search for "{{ searchQuery }}" found no results.
            </v-alert>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <table class="my-2">
                <tr>
                  <td>
                    {{ $t("FORM_PLACEHOLDERS.MACHINE") + ":" }}
                  </td>
                  <td class="pl-2">
                    {{ item.machineInfo.machineModel }}
                    {{ item.machineInfo.machineName }} - {{ item.staffName }}
                  </td>
                </tr>
                <tr>
                  <td>
                    {{
                      $t("FORM_PLACEHOLDERS.MACHINE_REGISTRATION_PLATE") + ":"
                    }}
                  </td>
                  <td class="pl-2">
                    {{ item.machineInfo.registrationPlate }}
                  </td>
                </tr>
                <tr v-if="item.note">
                  <td>
                    {{ $t("FORM_LABELS.NOTE") + ":" }}
                  </td>
                  <td class="pl-2">
                    {{ item.note }}
                  </td>
                </tr>
              </table>

              <table class="my-2">
                <table class="work-table">
                  <thead>
                    <th style="padding: 0 10px;">
                      {{ $t("FORM_LABELS.WORK_DATE") }}
                    </th>
                    <th style="padding: 0 10px;">
                      {{ $t("FORM_LABELS.SERVICE") }}
                    </th>
                    <th style="padding: 0 10px;">
                      {{ $t("FORM_LABELS.QTY") }}
                    </th>
                    <th style="padding: 0 10px;">
                      {{ $t("FORM_LABELS.MEASURE_NAME") }}
                    </th>
                    <th style="padding: 0 10px;">
                      {{ $t("FORM_LABELS.NOTE") }}
                    </th>
                  </thead>
                  <tbody>
                    <tr v-for="w in item.work" :key="w.id">
                      <td style="padding: 0 10px;">
                        {{ w.dateWorked | moment("DD.MM.YYYY") }}
                      </td>
                      <td style="padding: 0 10px;">
                        {{ w.serviceName }}
                      </td>
                      <td style="padding: 0 10px;">{{ w.serviceQty }}</td>
                      <td style="padding: 0 10px;">{{ w.measureName }}</td>
                      <td style="padding: 0 10px;">{{ w.note }}</td>
                    </tr>
                  </tbody>
                </table>
              </table>
            </td>
          </template>
        </v-data-table>
      </v-card>

      <v-skeleton-loader
        v-if="isPageLoading"
        :loading="isPageLoading"
        type="table"
      ></v-skeleton-loader>
    </b-card>
  </div>
</template>

<script>
//General
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

import { mapGetters } from "vuex";

import SearchTools from "@/core/components/table/SearchTools.vue";
import FormDatepicker from "@/view/content/forms/components/FormDatepicker.vue";
import FormSelect from "@/view/content/forms/components/FormSelect.vue";
import FormInput from "@/view/content/forms/components/FormInput.vue";

import { FETCH_BILL_OF_LADINGS } from "../store/bill-of-lading.module";
import { FETCH_ASSIGNORS } from "@/modules/assignors/store/assignors.module";
import { FETCH_ASSIGNOR_LOCATIONS } from "@/modules/assignorslocation/store/location.module";
import { FETCH_PROTOCOLS } from "@/modules/protocols/store/protocol.module";

import { permissionMixin } from "@/core/mixins/permissionMixin.js";

export default {
  name: "BillOfLadingList",
  mixins: [permissionMixin],
  components: {
    SearchTools,
    FormSelect,
    FormDatepicker,
    FormInput,
  },
  data() {
    return {
      isPageLoading: true,
      expanded: [],
      items: [],
      totalItems: 100,
      searchQuery: "",
      options: {
        sortBy: ["bolDate"],
        sortDesc: [true],
        page: 1,
        itemsPerPage: 30,
        totalItems: 100,
      },
      filters: {
        dateFrom: null,
        dateТо: null,
        protocolId: null,
        assignorId: null,
        locationId: null,
        serviceQty: null,
      },
      rowsPerPageItems: [10, 20, 30, 40, 50],
      columns: {},
      cancelSource: null,
      headers: [
        {
          text: this.$i18n.t("MENU.ITEM.ACTIVITIES.BILL_OF_LADING_DATE"),
          value: "bolDate",
        },
        {
          text: this.$i18n.t("MENU.ITEM.ACTIVITIES.BILL_OF_LADING_NUMBER"),
          value: "billNumber",
        },
        {
          text: this.$i18n.t("MENU.ITEM.ASL.ASSIGNOR"),
          value: "assignorName",
          sortable: false,
        },
        {
          text: this.$i18n.t("MENU.ITEM.ASL.LOCATION"),
          value: "locationName",
          sortable: false,
        },
        {
          text: this.$i18n.t("FORM_LABELS.PROTOCOL"),
          value: "protocolName",
          sortable: false,
        },

        {
          align: "right",
          text: this.$i18n.t("MENU.ITEM.ACTIONS"),
          value: "actions",
          sortable: false,
          width: "10%",
        },
      ],
      assignors: [],
      locations: [],
      protocols: [],
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$i18n.t("MENU.ITEM.ACTIVITIES.BILL_OF_LADING"),
        route: { name: "list-bill-of-lading" },
      },
      { title: this.$i18n.t("MENU.ITEM.ASSETS.LIST") },
    ]);
    this.$store
      .dispatch(
        FETCH_ASSIGNORS,
        this.$url.transformParams({
          onlySelectValues: true,
          selectValueKey: "company_name",
        })
      )
      .then((data) => {
        this.assignors = data.data.items;
        this.assignors.unshift({
          id: 0,
          name: this.$i18n.t("FORM_LABELS.NO_ASSIGNOR"),
        });
      });

    this.fetchAssignorLocations();
    this.fetchProtocols();
  },
  computed: {
    ...mapGetters(["getFuelExpense", "isLoadingBillOfLading"]),
    params() {
      return {
        ...this.options,
        ...this.filters,
        query: this.searchQuery,
      };
    },
    tableHeaders() {
      let vm = this;
      return vm.headers;
    },
  },
  methods: {
    fetchAssignorLocations(id = null) {
      this.$store
        .dispatch(
          FETCH_ASSIGNOR_LOCATIONS,
          this.$url.transformParams({
            assignorId: id,
          })
        )
        .then((data) => {
          this.locations = data.data.items;
          if (this.locations.length === 1) {
            this.filters.locationId = this.locations[0].id;
          } else {
            this.filters.locationId = null;
          }
          this.locations.unshift({
            id: 0,
            name: this.$i18n.t("FORM_LABELS.NO_LOCATION"),
          });
        });
    },
    fetchProtocols(id = null) {
      this.$store
        .dispatch(
          FETCH_PROTOCOLS,
          this.$url.transformParams({
            onlySelectValues: true,
            locationId: id,
          })
        )
        .then((data) => {
          this.protocols = data.data.items;
          this.protocols.unshift({
            id: 0,
            name: this.$i18n.t("FORM_LABELS.NO_PROTOCOL"),
          });
        });
    },
    cancelRequest() {
      //Axios cancelSource to stop current search if new value is entered
      if (this.cancelSource) {
        this.cancelSource.cancel("Start new search, stop active search");
      }
    },
    doSearch() {
      let vm = this;
      if (vm.options.page == 1) {
        vm.getDataFromApi();
      } else {
        vm.options.page = 1;
      }
    },
    doClear() {
      let vm = this;
      vm.searchQuery = "";
      Object.keys(vm.filters).forEach((key) => {
        vm.filters[key] = null;
      });

      if (vm.options.page == 1) {
        vm.getDataFromApi();
      } else {
        vm.options.page = 1;
      }
    },
    getDataFromApi() {
      let vm = this;
      // vm.tableLoading = true;
      //copy current params to modify
      let params = this.params;

      params.length = params.itemsPerPage; //set how many records to fecth per page
      params.start =
        params.page == 1 ? 0 : params.itemsPerPage * (params.page - 1); //set offset
      // console.log(this.params);
      let apiParams = vm.$url.transformParams(params);
      // console.log(apiParams);
      vm.$store
        .dispatch(FETCH_BILL_OF_LADINGS, apiParams)
        .then((data) => {
          vm.firstLoader = false;
          vm.$nextTick(function() {
            vm.items = data.data.items;
            vm.totalItems = data.data.totalItemsCount;

            if (vm.isPageLoading == true) {
              vm.isPageLoading = false;
            }
          });
        })
        .catch((response) => {
          if (response.status === 404) {
            vm.$notify({
              group: "notify",
              type: "error",
              text: "Not Found",
            });
            this.$router.push({ name: "list-fuel-expenses" });
          }
        });
    },
  },
};
</script>
